<template>
  <div>
    <!--        <div class="leisure_buttons_group">-->
    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_TOKENGAME}"-->
    <!--                         :to="{path:'/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}">-->
    <!--              토큰게임-->
    <!--            </router-link>-->

    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_LOTUS}"-->
    <!--                         :to="{path:'/leisure/lotus_bakara', query: {t: new Date().getTime()}}">-->
    <!--                로투스-->
    <!--            </router-link>-->

    <!--        </div>-->
    <div class="leisure_buttons_group">
      <router-link tag="button" class="bw3 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_EOS}"
                   :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/cenha/mini/ntry.png"/> 엔트리
      </router-link>


      <router-link tag="button" class="bw3 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"
                   :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">
        <img src="../../assets/images/icon/cenha/mini/Bet365.png"/> BET365
      </router-link>
      <router-link tag="button" class="bw3 btn_minigame_comp"
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_TOKENGAME}"
                   :to="{path:'/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}">
        토큰게임
      </router-link>
<!--      <router-link tag="button" class="bw4 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_DONGHANG}"
                   :to="{path:'/leisure/donghangpowerball', query: {t: new Date().getTime()}}">
        동행복권
      </router-link>-->
      <!--            <router-link tag="button" class="bw3 btn_minigame_comp "-->
      <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NEXT}"-->
      <!--                         :to="{path:'/leisure/next_ring1m', query: {t: new Date().getTime()}}">-->
      <!--                <img src="../../assets/images/icon/cenha/mini/next.png"/> 넥스트-->
      <!--            </router-link>-->
    </div>

    <div class="leisure_buttons_group">
      <router-link tag="button" class="bw3 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_DONGHANG}"
                   :to="{path:'/leisure/donghangpowerball', query: {t: new Date().getTime()}}">
        동행복권
      </router-link>
      <router-link tag="button" class="bw3 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_PBG}"
                   :to="{path:'/leisure/pbgpowerball', query: {t: new Date().getTime()}}">
        PBG파워볼
      </router-link>
      <router-link tag="button" class="bw3 btn_minigame_comp "
                   :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BETPIC}"
                   :to="{path:'/leisure/betpicbgpowerball', query: {t: new Date().getTime()}}">
        보글게임
      </router-link>
    </div>
    <!--        <div class="leisure_buttons_group">-->

    <!--            <router-link tag="button" class="bw3 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SUREMAN}"-->
    <!--                         :to="{path:'/leisure/suremanpowerball1m', query: {t: new Date().getTime()}}">-->
    <!--                <img src="../../assets/images/icon/cenha/mini/skypark.png"/> 슈어맨-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw3 btn_minigame_comp "-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NAMED}"-->
    <!--                         :to="{path:'/leisure/npowerball3s', query: {t: new Date().getTime()}}">-->
    <!--                <img src="../../assets/images/icon/cenha/mini/named.png"/> 네임드-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw3 btn_minigame_comp "-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"-->
    <!--                         :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">-->
    <!--                <img src="../../assets/images/icon/cenha/mini/bos.png"/> 보스코어-->
    <!--            </router-link>-->
    <!--        </div>-->


    <!--        <div class="leisure_buttons_group">-->
    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_TOKENGAME}"-->
    <!--                         :to="{path:'/tgame365/tgame365baccarat', query: {t: new Date().getTime()}}">-->
    <!--                토큰게임-->
    <!--            </router-link>-->

    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_LOTUS}"-->
    <!--                         :to="{path:'/leisure/lotus_bakara', query: {t: new Date().getTime()}}">-->
    <!--                로투스-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_SKY}"-->
    <!--                         :to="{path:'/leisure/sky_speed_bakara', query: {t: new Date().getTime()}}">-->
    <!--                스카이파크-->
    <!--            </router-link>-->

    <!--            <router-link tag="button" class="bw3 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_MGM}"-->
    <!--                         :to="{path:'/leisure/mgm_bakara', query: {t: new Date().getTime()}}">-->
    <!--                MGM-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw3 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_CROWN}"-->
    <!--                         :to="{path:'/leisure/sutda', query: {t: new Date().getTime()}}">-->
    <!--                크라운-->
    <!--            </router-link>-->
    <!--        </div>-->

    <!--        <div class="leisure_buttons_group">-->
    <!--            <router-link tag="button" class="bw2 btn_minigame_comp"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_EOS}"-->
    <!--                         :to="{path:'/leisure/eospowerball1m', query: {t: new Date().getTime()}}">-->
    <!--                <img src="../../assets/images/icon/cenha/mini/ntry.png"/> 엔트리-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw3 btn_minigame_comp "-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NAMED}"-->
    <!--                         :to="{path:'/leisure/npowerball3s', query: {t: new Date().getTime()}}">-->
    <!--                <img src="../../assets/images/icon/cenha/mini/named.png"/> 네임드-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="btn_minigame_comp bw2"-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_BET365}"-->
    <!--                         :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">-->
    <!--                <img src="../../assets/images/icon/cenha/mini/Bet365.png"/> BET365-->
    <!--            </router-link>-->

    <!--        </div>-->
    <!--        <div class="leisure_buttons_group">-->


    <!--            <router-link tag="button" class="bw3 btn_minigame_comp "-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_STAR_SADALI}"-->
    <!--                         :to="{path:'/leisure/ssd1', query: {t: new Date().getTime()}}">-->
    <!--                <img src="../../assets/images/icon/cenha/mini/bos.png"/> 보스코어-->
    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw3 btn_minigame_comp "-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NEXT}"-->
    <!--                         to="">-->

    <!--            </router-link>-->
    <!--            <router-link tag="button" class="bw3 btn_minigame_comp "-->
    <!--                         :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_PAMGAME}"-->
    <!--                         :to="{path:'/leisure/pamgamemario', query: {t: new Date().getTime()}}">-->
    <!--                <img src="../../assets/images/icon/cenha/mini/prm.png"/> 파라문게임-->
    <!--            </router-link>-->
    <!--        </div>-->

    <!--<div class="leisure_buttons_group">
        <router-link tag="button" class="bw3 btn_minigame_comp "
                     :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_GAMEMOA}"
                     :to="{path:'/leisure/speedgamepdali', query: {t: new Date().getTime()}}">
            게임모아
        </router-link>


        <router-link tag="button" class="bw4 btn_minigame_comp"
                     :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_TGAMEPOWERBALL}"
                     :to="{path:'/leisure/tgamepowerball', query: {t: new Date().getTime()}}">
            토큰파워볼
        </router-link>

        <router-link tag="button" class="bw3 btn_minigame_comp "
                     :class="{'btn_leisure_active':this.$store.state.currentLeisureGameComp===leisureConst.LEISURE_COMP_NEXT}"
                     :to="{path:'/leisure/next_bakara', query: {t: new Date().getTime()}}">
            넥스트
        </router-link>
    </div>-->
  </div>


</template>
<script>
import sportsConst from "../../common/sportsConst";
import leisureConst from "../../common/leisureConst";
import {tgamebetcount} from "../../network/userRequest";

export default {
  name: "LeisureGameCompLinks",
  data() {
    return {
      leisureConst: leisureConst,

    }
  },
  methods: {
    commingsoon() {
      alert('준비중입니다')
    },
  },
  created() {

  }
}
</script>

<style scoped>
.leisure_buttons_group img {
  width: 25px;
}
</style>