<template>
    <div>
        <div class="leisure_button_group_sub">


            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE}"
                         :to="{path:'/leisure/bet365_superleague', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_soccer.svg" style="width: 15px;height: 15px"
                     alt=""> 슈퍼리그</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP}"
                         :to="{path:'/leisure/bet365_eurocup', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_soccer.svg" style="width: 15px;height: 15px"
                     alt=""> 유로컵</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP}"
                         :to="{path:'/leisure/bet365_worldcup', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_soccer.svg" style="width: 15px;height: 15px"
                     alt="">  월드컵</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP}"
                         :to="{path:'/leisure/bet365_premiership', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_soccer.svg" style="width: 15px;height: 15px"
                     alt=""> 프리미어</router-link>
            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_BASKETBALL_WARTER}"
                         :to="{path:'/leisure/bet365_water', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_basketball.svg" style="width: 15px;height: 15px"
                     alt=""> 워터포드</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER}"
                         :to="{path:'/leisure/bet365_baker', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_basketball.svg" style="width: 15px;height: 15px"
                     alt=""> 베이커</router-link>


            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK}"
                         :to="{path:'/leisure/bet365_goldenhill', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_dog.svg" style="width: 15px;height: 15px"
                     alt=""> 골든힐</router-link>

            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK}"
                         :to="{path:'/leisure/bet365_hillside', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_dog.svg" style="width: 15px;height: 15px"
                     alt=""> 힐사이드</router-link>

            <router-link tag="button" class="bw4"
                         :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_2}"
                         :to="{path:'/leisure/bet365_horserace2', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_horse.svg" style="width: 15px;height: 15px"
                     alt=""> 브리타니아
            </router-link>
            <router-link tag="button" class="bw4"
                         :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_3}"
                         :to="{path:'/leisure/bet365_horserace3', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_horse.svg" style="width: 15px;height: 15px"
                     alt=""> 페스티벌
            </router-link>
            <router-link tag="button" class="bw4"
                         :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_4}"
                         :to="{path:'/leisure/bet365_horserace4', query: {t: new Date().getTime()}}">
                <img src="../../assets/images/icon/leisure/m_horse.svg" style="width: 15px;height: 15px"
                     alt=""> 빅토리아
            </router-link>
        </div>

        <div class="leisure_button_group_sub">
<!--            <router-link tag="button" class="bw4"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE}"-->
<!--                         :to="{path:'/leisure/bet365_horserace', query: {t: new Date().getTime()}}">-->
<!--                <img src="../../assets/images/icon/leisure/m_horse.svg" style="width: 15px;height: 15px"-->
<!--                     alt=""> 트렌담 파크</router-link>-->

<!--            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_2}"-->
<!--                         :to="{path:'/leisure/bet365_horserace2', query: {t: new Date().getTime()}}">-->
<!--                <img src="../../assets/images/icon/leisure/m_horse.svg" style="width: 15px;height: 15px"-->
<!--                     alt=""> 브리타니아 웨이</router-link>-->
<!--            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_3}"-->
<!--                         :to="{path:'/leisure/bet365_horserace3', query: {t: new Date().getTime()}}">-->
<!--                <img src="../../assets/images/icon/leisure/m_horse.svg" style="width: 15px;height: 15px"-->
<!--                     alt=""> 페스티벌 다운즈</router-link>-->
<!--            <router-link tag="button" class="bw3"  :class="{'btn_leisure_sub_active':$store.state.currentLeisureGameID === leisureConst.LEISURE_KIND_BET365_HORCERACE_4}"-->
<!--                         :to="{path:'/leisure/bet365_horserace4', query: {t: new Date().getTime()}}">-->
<!--                <img src="../../assets/images/icon/leisure/m_horse.svg" style="width: 15px;height: 15px"-->
<!--                     alt=""> 빅토리아 파크</router-link>-->

        </div>


    </div>




</template>
<script>
    import sportsConst from "../../common/sportsConst";
    import leisureConst from "../../common/leisureConst";

    export default {
        name: "LeisureCompBet365GameLinks",
        props: {
            comp: {
                type: Number,
                default() {
                    return -1
                }
            },
        },
        data(){
            return {
                leisureConst:leisureConst,
            }
        }
    }
</script>

<style scoped>

</style>